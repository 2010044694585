
import { defineComponent, computed, ref, watch, nextTick, onMounted, onUnmounted } from "vue";
import { getSession, readFile, TYPE_STATUS, PRIORITY } from "@/utils";
import { createTaskList, updateTask, getIterationDemand } from "@/api/request-modules/iteration";
import { getIterateList } from "@/api/request-modules/board";

import xlsx from "xlsx";
import { ResponseParams } from "@/types/response";
import useMessageTip from "@/composables/useMessageTip";
import { useRoute } from "vue-router";
import useMixin from "../useMixin";
import { ElLoading } from "element-plus";
import { CirclePlus, Remove } from "@element-plus/icons";
import { useStore } from "@/store/index";
import { USER } from "@/store/state";
import dayjs from "dayjs";
import router from "@/router";
import { systemBehaviorStore } from "@/utils/contantOptions";

export default defineComponent({
  components: { CirclePlus, Remove },
  setup() {
    const route = useRoute();
    const fileList = ref();
    const isEdit = ref(false);
    const user = getSession(systemBehaviorStore.systemUserinfo, true) as USER;

    const store = useStore();
    const { tipMessage } = useMessageTip();
    const curProductInfo = ref(getSession("productInfo", true) as any);
    // 获取当前迭代信息
    const currentIter = computed(() => store.getters.currentIter);
    const rawTableData: any = {
      // 任务名称
      name: "",
      // 指派给
      staff_name: user?.name,
      staff_no: user?.staff_no,
      // 类型
      type: 2,
      product_module_id: 0,
      demand_id: "无",
      level: 3,
      time: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      // 工时
      hours: 0,
      // 迭代id
      iteration_id: Number(route.query.type) ? currentIter.value?.id : "",
      // 描述
      content: "",
      demandList: []
    };
    const isType = ref(Number(route.query.type) ? true : false);
    const tableData: any = ref(
      Array(route.query.isEdit ? 1 : 10)
        .fill({})
        .map(() => {
          return { ...rawTableData };
        })
    );

    // 遍历过滤是否在该项目下存在迭代
    const filrerDemandId = (id: number) => {
      const arr = demandLists_last.value?.filter((it: any) => it.id === id);
      if (arr && arr.length) {
        return arr[0].id || "";
      }
      return "";
    };

    // 迭代下拉列表
    let newIterateListData_last: any = ref([]);
    // 迭代下拉列表

    // 所有迭代下的需求列表
    const allDemandListData: any = ref([]);
    // 需求下拉列表
    const demandLists_last: any = ref([]);
    interface DemandData {
      product_id: number;
      iteration_id?: string;
    }

    const obj: DemandData = {
      product_id: curProductInfo.value?.id,
      iteration_id: Number(route.query.type) ? currentIter.value?.id : ""
    };
    const getDemandData = (params: DemandData) => {
      getIterationDemand<ResponseParams.ResponseDataSuccess>(params).then((res: any) => {
        if (res.data && res.data.length) {
          demandLists_last.value = res.data;
          demandLists_last.value.unshift({ name: "无", id: 0 });
        } else {
          demandLists_last.value = [{ name: "无", id: 0 }];
        }
      });

      if (!Number(route.query.type)) {
        getIterationDemand<ResponseParams.ResponseDataSuccess>({ product_id: curProductInfo.value?.id }).then((res: any) => {
          if (res.data && res.data.length) {
            allDemandListData.value = res.data;
            allDemandListData.value.unshift({ name: "无", id: 0 });
          } else {
            allDemandListData.value = [{ name: "无", id: 0 }];
          }
        });
      }
      getIterateList<ResponseParams.ResponseDataSuccess>({ product_id: curProductInfo.value?.id }).then((res) => {
        if (res.code === 200) {
          newIterateListData_last.value = res.data;
          newIterateListData_last.value.unshift({ name: "无", id: 0 });
        } else {
          newIterateListData_last.value = [{ name: "无", id: 0 }];
        }
      });
    };
    onMounted(() => {
      getDemandData(obj);
    });

    onUnmounted(() => {
      // 页面卸载时，需要把type 参数给去掉
      router.replace({
        path: route.path,
        query: {
          ...route.query,
          type: undefined
        }
      });
    });

    // 需求下拉列表

    // 选择迭代过滤当前迭代下的需求
    const filrerDemandData = (id: number | string) => {
      const arr = allDemandListData.value?.filter((it: any) => it.iteration_id == id);
      arr.unshift({ name: "无", id: 0 });
      return arr;
    };

    let loading = ElLoading.service({ fullscreen: true });

    setTimeout(() => {
      loading.close();
    }, 2000);

    if (route.query.isEdit) {
      setTimeout(() => {
        const userInfo = getSession("editTask", true) as any;
        isEdit.value = true;
        if (route.query.isEdit && route.query.isEdit === "2") {
          // 复制旧数据批量新增
          isEdit.value = false;
        }
        userInfo.forEach((item: Record<string, any>) => {
          item.time = [item.begin_time, item.end_time] as Array<string>;
          item.demand_id = filrerDemandId(item.demand_id);
          item.content = item?.content;
          item.iteration_id = item.iteration_id ? item.iteration_id : "";
          item.demandList = JSON.parse(JSON.stringify(demandLists_last.value));
        });

        tableData.value = userInfo;
      }, 1000);
    } else {
      // 填充数据处理，将类型，指派给默认设置为同上
      setTimeout(() => {
        tableData.value.forEach((v: Record<string, any>, index: number) => {
          if (index >= 1) {
            v.staff_no = user?.staff_no;
            v.staff_name = user?.name;
            v.type = "同上";
            v.level = "同上";
            v.demand_id = "无";
            v.time = [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")];
          }
          v.demandList = demandLists_last.value;
        });
      }, 1000);
    }

    // 优先级
    const CASEPRIORITY = PRIORITY.slice();
    const CASE_PRIORITY_LAST = [{ value: "同上", id: -1 }].concat(PRIORITY.slice());
    // 类型
    const TYPE_STATUS_DELETE_ALL = TYPE_STATUS.slice();
    const TYPE_STATUS_LAST = [{ label: "同上", value: -1 }].concat(TYPE_STATUS.slice());

    const { handleGetIterationList } = useMixin();
    handleGetIterationList();
    const { employeeLists, newEmployeeLists } = useMixin();

    // 指派人列表
    let employeeLists_last = computed(() => [{ staff_name: "同上", value: -1 }].concat(newEmployeeLists.value));

    // 点击迭代事件
    const handleIterationId = (val: string, idx: number) => {
      const i = idx - 1;
      tableData.value[i].demandList = filrerDemandData(val);
      tableData.value[i].demand_id = 0;
    };
    // 点击迭代事件

    // 选择需求
    const handleDemand = (val: string, idx: number) => {
      const newDemandList = JSON.parse(JSON.stringify(demandLists_last.value));
      const a = newDemandList.filter((item: any) => item.id == val);
      if (a && a.length) {
        if (a[0].id) {
          tableData.value[idx - 1].iteration_id = a[0]?.iteration_id || "";
        }
      }
    };
    // 选择需求

    const handlePlusTable = (index: number) => {
      tableData.value.splice(
        index + 1,
        0,
        Object.assign(
          { ...rawTableData },
          {
            staff_no: "-1",
            type: "同上",
            staff_name: "同上",
            level: "同上",
            demand_id: "无",
            demandList: demandLists_last.value
          }
        )
      );
    };
    const handleRemoveTable = (item: number) => {
      if (tableData.value.length <= 1) {
        return;
      }
      tableData.value.splice(item, 1);
    };
    // 检查日期是否正确
    const checkDate = (strInputDate: string) => {
      if (!strInputDate) return false;
      strInputDate = strInputDate.replace(/-/g, "/");
      var d: any = new Date(strInputDate);
      if (isNaN(d)) return false;
      var arr = strInputDate.split("/");
      return parseInt(arr[0], 10) == d.getFullYear() && parseInt(arr[1], 10) == d.getMonth() + 1 && parseInt(arr[2], 10) == d.getDate();
    };

    // 快捷导入弹框
    const exportShow = ref(false);
    // 文件上传前判断
    const beforeAvatarUpload = (file: any) => {
      if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        return tipMessage(400, `上传格式不正确，请上传xls或者xlsx格式`);
      }
    };
    const fileName = ref("");
    const exportData: any = ref([]);
    let timer: ReturnType<typeof setTimeout>;
    const uploadChange = async (file: any) => {
      if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        fileList.value.clearFiles();
        fileName.value = "";
        return tipMessage(400, `上传格式不正确，请上传xls或者xlsx格式`);
      }
      clearTimeout(timer);
      timer = setTimeout(async () => {
        let dataBinary = await readFile(file.raw);
        let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
        let workSheet = workBook.Sheets[workBook.SheetNames[0]];
        const data: any = xlsx.utils.sheet_to_json(workSheet, { raw: false });
        if (data && data.length) {
          const resultTable = [];
          for (let i = 0, l = data.length; i < l; i++) {
            if (data[i]["任务名称"]) {
              const obj: Record<string, any> = {};
              obj.name = data[i]["任务名称"];
              const type = TYPE_STATUS_DELETE_ALL.filter((item: Record<string, any>) => item.label === data[i]["类型"]);
              const level = CASEPRIORITY.filter((item: Record<string, any>) => item.value === data[i]["优先级"]);
              obj.type = type[0] ? type[0].value : -1;
              if (level.length) {
                obj.level = level[0].id ? level[0].id : "";
              } else {
                obj.level = "";
              }
              if (data[i]["工时"]) {
                obj.hours = data[i]["工时"] ? data[i]["工时"] * 1 : "";
              } else {
                obj.hours = data[i]["工时(h)"] ? data[i]["工时(h)"] * 1 : "";
              }

              obj.demand_id = 0;
              obj.staff_name = user?.name;
              obj.staff_no = user?.staff_no;
              if (data[i]["指派给"]) {
                const item = newEmployeeLists.value.find((employee: Record<string, any>) => employee.staff_name === data[i]["指派给"]);
                if (item) {
                  obj.staff_name = item.staff_name;
                  obj.staff_no = item.staff_no;
                }
              }

              const dateFormat1 = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/;
              let start = "";
              if (dateFormat1.test(data[i]["开始时间"])) {
                const begin = data[i]["开始时间"].split("/");
                let beginTime = begin[0];
                const m1 = begin[1] * 1 > 9 ? begin[1] : "0" + begin[1] * 1;
                const d1 = begin[2] * 1 > 9 ? begin[2] : "0" + begin[2] * 1;
                start = beginTime + "-" + m1 + "-" + d1;
                if (!checkDate(start)) start = "";
              }

              let end = "";
              if (dateFormat1.test(data[i]["结束时间"])) {
                const last = data[i]["结束时间"].split("/");
                let endTime = last[0];
                const m2 = last[1] * 1 > 9 ? last[1] : "0" + last[1] * 1;
                const d2 = last[2] * 1 > 9 ? last[2] : "0" + last[2] * 1;
                end = endTime + "-" + m2 + "-" + d2;
                if (!checkDate(end)) end = "";
              }
              obj.begin_time = start;
              obj.last_time = end;
              obj.time = [start, end] as Array<string>;
              // 判断如果开始时间大于结束时间则将时间清空
              if (start && end) {
                if (end < start) {
                  obj.begin_time = "";
                  obj.last_time = "";
                  obj.time = [];
                }
              }
              obj.content = data[i]["描述"] ? data[i]["描述"] : "";

              obj.product_id = curProductInfo.value?.id;
              obj.iteration_id = Number(route.query.type) ? currentIter.value?.id : "";
              obj.demand_id = 0;
              obj.demandList = JSON.parse(JSON.stringify(demandLists_last.value));

              resultTable.push(obj);
            }
          }
          exportData.value = resultTable;

          fileName.value = file.name;
          tipMessage(200, `上传成功`);
        } else {
          fileList.value.clearFiles();
          fileName.value = "";
          return tipMessage(400, `内容不能为空，请填写内容`);
        }
      }, 1000);
    };
    // 导入
    const handleExport = () => {
      exportShow.value = true;
    };
    // 取消导入
    const handleCancel = () => {
      exportShow.value = false;
    };

    // 确认导入数据
    const handleExportData = () => {
      if (!exportData.value.length) return tipMessage(400, `请上传xls或者xlsx格式文件`);
      let loadingInstance1 = ElLoading.service({ fullscreen: true });
      setTimeout(() => {
        let originTable = JSON.parse(JSON.stringify(tableData.value));
        exportShow.value = false;
        for (let i = originTable.length - 1; i >= 0; i--) {
          originTable[i].iteration_id = Number(route.query.type) ? currentIter.value?.id : "";
          originTable[i].demandList = JSON.parse(JSON.stringify(demandLists_last.value));
          if (!originTable[i].name) {
            originTable.splice(i, 1);
          } else {
            break;
          }
        }

        tableData.value = originTable;
        tableData.value.push.apply(tableData.value, exportData.value);

        nextTick(() => {
          loadingInstance1.close();
        });
      }, 300);
    };
    watch(
      () => exportShow.value,
      () => {
        exportData.value = [];
        fileName.value = "";
        if (!exportShow.value) {
          fileList.value.clearFiles();
        }
      }
    );

    const handleConfirmSave = () => {
      const post_table = JSON.parse(JSON.stringify(tableData.value));
      if (!post_table[0]?.name) return tipMessage(400, `请输入第1条的任务名称`);
      const resultTable = post_table.filter((v: Record<string, any>) => v.name !== "");
      if (resultTable.length) {
        if (!resultTable[0].staff_name || resultTable[0].staff_name === "同上") return tipMessage(400, `请选择第1条的指派人`);
        if (resultTable[0].type === "同上" || resultTable[0].type === -1) return tipMessage(400, `请选择第1条的类型`);
      }
      resultTable.forEach((itemObj: Record<string, any>, index: number) => {
        for (let key in itemObj) {
          if (itemObj[key] === -1 || itemObj[key] === "同上") {
            itemObj[key] = resultTable[index - 1][key as keyof typeof rawTableData];
          }
          if (key === "staff_name" && itemObj[key] && itemObj[key] !== "所有人") {
            const staff_no = newEmployeeLists.value.find((employee: Record<string, any>) => employee.staff_name === itemObj[key])?.staff_no;
            resultTable[index].staff_no = staff_no;
          }
        }
        if (itemObj.type === "") {
          tipMessage(400, `请选择类型`);
          throw new Error("未选择类型！");
        }
        if (itemObj.staff_name === "" || itemObj.staff_no === "") {
          tipMessage(400, `请选择指派人`);
          throw new Error("未选择指派人！");
        }
        if (itemObj.time && itemObj.time.length) {
          itemObj.begin_time = itemObj.time[0];
          itemObj.end_time = itemObj.time[1];
        } else {
          tipMessage(400, `请选择起止时间`);
          throw new Error("未选择起止时间！");
        }
        if (!itemObj.end_time || !itemObj.begin_time) {
          tipMessage(400, `请选择起止时间`);
          throw new Error("未选择起止时间！");
        }
        delete itemObj.time;
        if (!itemObj.level) {
          tipMessage(400, `请选择优先级`);
          ``;
          throw new Error("未选择优先级！");
        }
        if (!itemObj.hours || !itemObj.hours.toString().match(/\d/g)) {
          tipMessage(400, `请输入工时大于0的正整数`);
          throw new Error("工时错误！");
        }
        if (itemObj.hours < 0 || itemObj.hours > 1000) {
          tipMessage(400, `请输入工时0到999的正整数`);
          throw new Error("工时错误！");
        }
        if (!itemObj.demand_id) itemObj.demand_id = 0;
        if (itemObj.demand_id === "无") itemObj.demand_id = 0;
        if (!itemObj.iteration_id) itemObj.iteration_id = 0;
        itemObj.product_id = curProductInfo.value?.id;
        itemObj.content = itemObj.content ? `<div>${itemObj.content}</div>` : "";
      });
      if (resultTable.length) {
        if (isEdit.value) {
          updateTask<ResponseParams.ResponseDataSuccess>(resultTable).then((res) => {
            successCallBack(res.code);
          });
        } else {
          let loadingInstance1 = ElLoading.service({ fullscreen: true });

          createTaskList<ResponseParams.ResponseDataSuccess>(resultTable).then((res) => {
            loadingInstance1.close();
            successCallBack(res.code);
          });
        }
      } else {
        localStorage.setItem("setTask", "taskOption");
        window.close();
      }

      const successCallBack = (code: number) => {
        tipMessage(code);
        if (code === 200) {
          localStorage.setItem("setTask", "taskOption");
          window.close();
        }
      };
    };
    return {
      handleConfirmSave,
      TYPE_STATUS_DELETE_ALL,
      TYPE_STATUS_LAST,
      CASE_PRIORITY_LAST,
      CASEPRIORITY,
      employeeLists,
      newEmployeeLists,
      employeeLists_last,
      demandLists_last,
      handleRemoveTable,
      handlePlusTable,
      tableData,
      isEdit,
      fileList,
      exportShow,
      beforeAvatarUpload,
      uploadChange,
      handleExport,
      handleExportData,
      handleCancel,
      fileName,
      newIterateListData_last,
      handleIterationId,
      handleDemand,
      isType
    };
  }
});
